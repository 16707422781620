<template>
    <v-container fluid style="background-color: #485461;
        background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12">
                    <v-toolbar dark color="#4e419d">
                        <v-spacer />
                        <b>Recuperar senha</b>
                        <v-spacer />
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                                label="E-mail"
                                ref="login"
                                prepend-icon="person"
                                type="text"
                                v-model="email"
                                autocomplete="username"
                                @keypress.enter="$refs.password.focus()"
                            />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            block
                            @click="login"
                            color="#4e419d"
                            :loading="loading"
                            dark
                        >Recuperar</v-btn>
                    </v-card-actions>
                    <v-card-actions>
                        <v-btn
                            block
                            text
                            small
                            @click="goToLogin"
                            color="#4e419d"
                            class="white--text"
                        >Voltar ao login</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ForgotPassword',

    data: () => ({
        email: '',
        loading: false,
        showPassword: false,
    }),

    methods: {
        login() {
            if (!this.email) {
                this.$toast.error('Informe o e-mail', 'warning');
                return;
            }

            this.loading = true;
            this.$http.post('forgot-password', {
                email: this.email,
            }).then(async (resp) => {
                const data = resp.data;

                if (data.type == 'warning') {
                    this.$toast.error(data.msg);
                    return;
                }

                this.$toast.success(data.msg);

                // this.$router.push('new-password');
                this.$router.push({ path: 'new-password', query: { email: encodeURIComponent(this.email) }});
            })
            .catch(e => (console.log(e)))
            .finally(() => (this.loading = false))
        },

        goToLogin() {
            sessionStorage.clear();
            this.$store.commit('setUser', {});
            this.$router.push('/login');
        },
    }
}
</script>

<style scoped>
.container  {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.container .row {
    margin: auto;
    justify-content: center;
}
</style>
